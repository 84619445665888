"use client";
import { NextPage } from "next";
import { useRouter } from "next/navigation";

const NotFound: NextPage = () => {
  const router = useRouter();
  return (
    <div className="text-primary-dark flex flex-col gap-4 items-center justify-center h-[80vh]">
      <div className="text-center">
        <h1 className="text-3xl font-bold">Page not found</h1>
        <p className="text-xl mt-4">
          Sorry, we couldn't find what you were looking for.But don't worry, you
          can find plenty of other things on our{" "}
          <span
            onClick={() => router.push("/")}
            className={"text-primary-blue cursor-pointer hover:underline"}
          >
            homepage
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default NotFound;
